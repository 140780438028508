<template>
	<div class="preloader">
		<div class="triangle-wrap">
			<div class="triangle triangle--main"></div>
			<div class="triangle triangle--red"></div>
			<div class="triangle triangle--blue"></div>
			<div class="triangle__text">
				<h2 class="dori--blue">dori</h2>
				<h2 class="dori--main">dori</h2>
				<h2 class="dori--red">dori</h2>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	mounted() {
		const preloader = document.querySelector(".preloader");
		if (preloader) {
			setTimeout(() => {
				const fadeEffect = setInterval(() => {
					if (!preloader.style.opacity) {
						preloader.style.opacity = 1;
					}
					if (preloader.style.opacity > 0) {
						preloader.style.opacity -= 0.1;
					} else {
						preloader.style.display = "none";
						clearInterval(fadeEffect);
					}
				}, 0.7);
			}, 3000);
		}
	},
};
</script>

<style scoped>
/* title font */
@font-face {
	font-family: "Japanese_3017";
	src: url("../assets/font/Japanese_3017.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
/* -------------------- PRELOADER -------------------- */

.preloader {
	display: flex;
	position: fixed;
	z-index: 100;
	width: 100vw;
	height: 100vh;
	background-color: #090909;
	font-family: Roboto;
	overflow: hidden;
	height: -webkit-fill-available;
}
.triangle {
	margin: auto;
	border-style: solid;
	border-width: 0 125px 216.5px 125px;
	border-color: transparent transparent #f2f2f0 transparent;
	-webkit-animation-name: triangleMove;
	animation-name: triangleMove;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	transition-timing-function: linear;
}
.triangle:after {
	position: absolute;
	top: 11.5px;
	left: -114px;
	display: block;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 115px 199.2px 115px;
	border-color: transparent transparent #090909 transparent;
}
.triangle-wrap {
	margin: auto;
	position: relative;
	width: 250px;
	height: 217px;
}
.triangle--main {
	position: relative;
	z-index: 10;
}
.triangle--red {
	left: 0;
	top: 0;
	position: absolute !important;
	border-color: transparent transparent red transparent;
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.dori--main {
	position: absolute !important;
	top: 30%;
	left: 30%;
	font-family: "Japanese_3017", sans-serif;
	color: #fff;
	font-size: 8rem;
	font-weight: 100;
	z-index: 1;
	-webkit-animation-name: triangleMove;
	animation-name: triangleMove;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	transition-timing-function: linear;
}
.dori--red {
	top: 30%;
	left: 30%;
	position: absolute !important;
	font-family: "Japanese_3017", sans-serif;
	font-size: 8rem;
	font-weight: 100;
	color: red;
	-webkit-animation-delay: 0.5s;
	animation-delay: 0.5s;
}
.dori--blue {
	top: 30%;
	left: 55%;
	position: absolute !important;
	font-family: "Japanese_3017", sans-serif;
	font-size: 8rem;
	font-weight: 100;
	color: blue;
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

.triangle--blue {
	left: 0;
	top: 0;
	position: absolute !important;
	border-color: transparent transparent blue transparent;
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.triangle__text {
	color: #fff;
	position: absolute;
	top: 22.5%;
	left: -20%;
	text-align: center;
	z-index: 99;
	/* text-transform: uppercase; */
	font-size: 30px;
	font-weight: 700;
	padding: 10px;
	letter-spacing: 20px;
}

img {
	width: 200px;
}
@-webkit-keyframes triangleMove {
	10% {
		transform: translateX(10px);
		filter: blur(3px);
	}
	20% {
		transform: translateX(4px);
		filter: blur(0);
	}
	30% {
		transform: translateX(-5px) skew(0deg);
		filter: blur(3px);
	}
	40% {
		transform: translateX(7px);
		filter: blur(0);
	}
	50% {
		transform: translateX(3px);
	}
	60% {
		transform: translateX(2px);
		filter: blur(5px);
	}
	70% {
		transform: translateX(-2px);
		filter: blur(0);
	}
	80% {
		transform: translateX(3px);
		filter: blur(2px);
	}
	90% {
		transform: translateX(-3px);
	}
	100% {
		transform: translateX(3px);
	}
}
@keyframes triangleMove {
	10% {
		transform: translateX(10px);
		filter: blur(3px);
	}
	20% {
		transform: translateX(4px);
		filter: blur(0);
	}
	30% {
		transform: translateX(-5px) skew(0deg);
		filter: blur(3px);
	}
	40% {
		transform: translateX(7px);
		filter: blur(0);
	}
	50% {
		transform: translateX(3px);
	}
	60% {
		transform: translateX(2px);
		filter: blur(5px);
	}
	70% {
		transform: translateX(-2px);
		filter: blur(0);
	}
	80% {
		transform: translateX(3px);
		filter: blur(2px);
	}
	90% {
		transform: translateX(-3px);
	}
	100% {
		transform: translateX(3px);
	}
}

/* ------------------------------------------------ RESPONSIVE ------------------------------------------------ */

@media screen and (max-width: 3840px) {
	.triangle-wrap {
		zoom: 200%;
	}
}
@media screen and (max-width: 2048px) {
	.triangle-wrap {
		zoom: 110%;
	}
}
@media screen and (max-width: 1705px) {
	.triangle-wrap {
		zoom: 95%;
	}
}
@media screen and (max-width: 1520px) {
	.triangle-wrap {
		zoom: 85%;
	}
}
@media screen and (max-width: 1520px) {
	.triangle-wrap {
		zoom: 75%;
	}
}
@media screen and (max-width: 1080px) {
	.triangle-wrap {
		zoom: 55%;
	}
}
@media screen and (max-width: 920px) {
	.triangle-wrap {
		zoom: 100%;
	}
}
@media screen and (max-width: 768px) {
	.triangle-wrap {
		zoom: 85%;
	}
}
@media screen and (max-width: 540px) {
	.triangle-wrap {
		zoom: 65%;
	}
}
@media screen and (max-width: 280px) {
	.triangle-wrap {
		zoom: 50%;
	}
}
</style>
