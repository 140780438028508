<template>
	<body>
		<header v-motion-fade>
			<video autoplay
				playsinline
				muted
				loop
				id="content">
<source src="../assets/video/website_intro_video.webm" type="video/webm">
<source src="../assets/video/website_intro_video.mp4" type="video/mp4">
</video>
		</header>
	</body>
</template>

<script>
// @ is an alias to /src
</script>

<style scoped>
/*-------------------- VIDEO --------------------*/
* {
	margin: 0;
	padding: 0;
}
header {
	position: absolute;
	width: 100%;
	height: 100vh;
	z-index: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	text-align: center;
	transition: 0.75s ease;
}
video {
	clip-path: inset(1px 1px);
}
#content {
	width: 80%;
}
@media screen and (max-width: 1667px) {
	#content {
		height: 100%;
		width: 100%;
	}
}
</style>
