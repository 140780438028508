import { createRouter, createWebHistory } from "vue-router";
import SplashView from "../views/SplashView.vue";

const routes = [
	{
		path: "/",
		name: "splash",
		component: SplashView,
		meta: {
			title: "Dori",
		},
	},
	{
		path: "/intro",
		name: "intro",
		component: () =>
			import(/* webpackChunkName: "intro" */ "../views/IntroView.vue"),
		meta: {
			title: "Dori | Intro",
		},
	},
	{
		path: "/values",
		name: "values",
		component: () =>
			import(/* webpackChunkName: "values" */ "../views/ValuesView.vue"),
		meta: {
			title: "Dori | Values",
		},
	},
	{
		path: "/roadmap",
		name: "roadmap",
		component: () =>
			import(
				/* webpackChunkName: "roadmap" */ "../views/RoadmapView.vue"
			),
		meta: {
			title: "Dori | Roadmap",
		},
	},
	{
		path: "/chooseComics",
		name: "comics",
		component: () =>
			import(
				/* webpackChunkName: "comics" */ "../views/ComicSelectView.vue"
			),
		meta: {
			title: "Dori | Comics",
		},
	},
	{
		path: "/comics-1",
		name: "comics-1",
		component: () =>
			import(/* webpackChunkName: "comics" */ "../views/ComicsView.vue"),
		meta: {
			title: "Dori | Comics",
		},
	},
	{
		path: "/gallery",
		name: "gallery",
		component: () =>
			import(
				/* webpackChunkName: "gallery" */ "../views/GalleryView.vue"
			),
		meta: {
			title: "Dori | Gallery",
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
