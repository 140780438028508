<template>
	<link
		rel="stylesheet"
		href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css"
		integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w=="
		crossorigin="anonymous"
		referrerpolicy="no-referrer"
	/>
	<link
		href="https://fonts.googleapis.com/css?family=IBM%20Plex%20Sans:400&display=swap"
		rel="stylesheet"
	/>
	<nav>
		<div class="left-nav">
			<img
				class="dori"
				src="../assets/img/dori-logo_web_black.png"
				alt="dori-logo_web_black"
				@click="goToHome"
			/>
		</div>
		<div class="right-nav" ref="desktopNav">
			<li class="btn-21">
				<router-link to="/intro"><span>Intro</span></router-link>
			</li>
			<li class="btn-21">
				<router-link to="/values"><span>Values</span></router-link>
			</li>
			<li class="btn-21">
				<router-link to="/roadmap"><span>Roadmap</span></router-link>
			</li>
			<li class="btn-21">
				<router-link to="/chooseComics"
					><span>Comics</span></router-link
				>
			</li>
			<li class="btn-21">
				<router-link to="/gallery"><span>Gallery</span></router-link>
			</li>
			<li class="btn-21" @click="goToHub">
				<span>Hub</span>
			</li>
			<li class="btn-21 buy-container">
				<span
					><div class="buy" @click="openLinks">
						links
						<i
							class="fa-solid fa-arrow-up-right-from-square"
						></i></div
				></span>
			</li>
			<a
				class="twitter"
				href="https://twitter.com/DoriSamurai"
				target="_blank"
			>
				<li class="btn-21 social">
					<span><i class="fa-brands fa-twitter"></i></span>
				</li>
			</a>
			<a
				class="discord"
				href="https://discord.com/invite/dori-samurai"
				target="_blank"
				><li class="btn-21 social">
					<span><i class="fa-brands fa-discord"></i></span></li
			></a>
		</div>
		<span class="menu-trigger">
			<i class="menu-trigger-bar top"></i>
			<i class="menu-trigger-bar middle"></i>
			<i class="menu-trigger-bar bottom"></i>
		</span>
		<span class="close-trigger">
			<i class="close-trigger-bar left"></i>
			<i class="close-trigger-bar right"></i>
		</span>
		<div class="mobile-container">
			<div class="inner-container">
				<i class="menu-bg top"></i>
				<i class="menu-bg middle"></i>
				<i class="menu-bg bottom"></i>
				<div class="menu-container">
					<ul class="menu">
						<li class="mobile-li">
							<router-link to="/" @click="closeMenu"
								>Home</router-link
							>
						</li>
						<li class="mobile-li">
							<router-link to="/intro" @click="closeMenu"
								>Intro</router-link
							>
						</li>
						<li class="mobile-li">
							<router-link to="/values" @click="closeMenu"
								>Values</router-link
							>
						</li>
						<li class="mobile-li">
							<router-link to="/roadmap" @click="closeMenu"
								>Roadmap</router-link
							>
						</li>
						<li class="mobile-li">
							<router-link to="/chooseComics" @click="closeMenu"
								>Comics</router-link
							>
						</li>
						<li class="mobile-li">
							<router-link to="/gallery" @click="closeMenu"
								>Gallery</router-link
							>
						</li>
						<li class="mobile-li" id="hub" @click="goToHub">
							Hub
							<img src="../assets/img/icon.png" alt="icon" />
						</li>
						<li class="link-li" id="opensea" @click="openOsGen">
							OS - Dori Samurai
							<img
								src="../assets/img/opensea.png"
								alt="opensea"
							/>
						</li>
						<li class="link-li" id="opensea" @click="openOs1776">
							OS - Dori 1776
							<img
								src="../assets/img/opensea.png"
								alt="opensea"
							/>
						</li>
						<li class="link-li" id="twitter" @click="openTwitter">
							Twitter
							<img
								src="../assets/img/twitter.png"
								alt="opensea"
							/>
						</li>
						<li class="link-li" id="discord" @click="openDiscord">
							Discord
							<img
								src="../assets/img/discordw.png"
								alt="opensea"
							/>
						</li>
						<li class="dori-mobile-logo">
							<img
								src="../assets/img/dori-logo_web.png"
								alt="dori-logo_web"
							/>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<img
			class="logoresp"
			src="../assets/img/dori-logo_web_black.png"
			alt="dori-logo_web_black"
			@click="goToHome"
		/>
	</nav>
</template>

<script>
import { gsap, Power4 } from "gsap";
export default {
	data() {
		return {
			showTooltip: false,
		};
	},
	methods: {
		closeMenu() {
			const tlClose = this.$data.tlClose;
			if (tlClose.progress() < 1) {
				tlClose.play();
			} else {
				tlClose.restart();
			}
		},
		goToHome() {
			this.$router.push("/");
		},
		goToHub() {
			window.open("https://hub.houseofdori.com/", "_blank");
		},
		openLinks() {
			window.open("https://linktr.ee/dorisamurai", "_blank");
		},
		openTwitter() {
			window.open("https://twitter.com/DoriSamurai", "_blank");
		},
		openOsGen() {
			window.open(
				"https://opensea.io/fr/collection/dori-samurai",
				"_blank"
			);
		},
		openOs1776() {
			window.open("https://opensea.io/fr/collection/dori1776", "_blank");
		},
		openDiscord() {
			window.open("https://discord.com/invite/dori-samurai", "_blank");
		},
	},
	watch: {
		$route(to, from) {
			document.title = to.meta.title || "My App";
		},
	},
	mounted() {
		//OPEN TRIGGER
		const openTrigger = document.querySelector(".menu-trigger");
		const openTriggerTop = openTrigger.querySelector(
			".menu-trigger-bar.top"
		);
		const openTriggerMiddle = openTrigger.querySelector(
			".menu-trigger-bar.middle"
		);
		const openTriggerBottom = openTrigger.querySelector(
			".menu-trigger-bar.bottom"
		);
		//CLOSE TRIGGER
		const closeTrigger = document.querySelector(".close-trigger");
		const closeTriggerLeft = closeTrigger.querySelector(
			".close-trigger-bar.left"
		);
		const closeTriggerRight = closeTrigger.querySelector(
			".close-trigger-bar.right"
		);
		//MENU
		const menuContainer = document.querySelector(".menu-container");
		const menu = document.querySelector(".menu");
		const menuTop = document.querySelector(".menu-bg.top");
		const menuMiddle = document.querySelector(".menu-bg.middle");
		const menuBottom = document.querySelector(".menu-bg.bottom");
		//TL
		const tlOpen = new gsap.timeline({ paused: true });
		const tlClose = new gsap.timeline({ paused: true });
		this.$data.tlClose = tlClose;
		//OPEN TIMELINE
		tlOpen
			.add("preOpen")
			.to(
				openTriggerTop,
				0.4,
				{
					x: "+80px",
					y: "-80px",
					delay: 0.1,
					ease: Power4.easeIn,
					onComplete: function () {
						closeTrigger.style.zIndex = "25";
					},
				},
				"preOpen"
			)
			.to(
				openTriggerMiddle,
				0.4,
				{
					x: "+=80px",
					y: "-=80px",
					ease: Power4.easeIn,
					onComplete: function () {
						openTrigger.style.visibility = "hidden";
					},
				},
				"preOpen"
			)
			.to(
				openTriggerBottom,
				0.4,
				{
					x: "+=80px",
					y: "-=80px",
					delay: 0.2,
					ease: Power4.easeIn,
				},
				"preOpen"
			)
			.add("open", "-=0.4")
			.to(
				menuTop,
				0.8,
				{
					y: "13%",
					ease: Power4.easeInOut,
				},
				"open"
			)
			.to(
				menuMiddle,
				0.8,
				{
					scaleY: 1,
					ease: Power4.easeInOut,
				},
				"open"
			)
			.to(
				menuBottom,
				0.8,
				{
					y: "-114%",
					ease: Power4.easeInOut,
				},
				"open"
			)
			.fromTo(
				menu,
				0.6,
				{
					y: 30,
					opacity: 0,
					visibility: "hidden",
				},
				{
					y: 0,
					opacity: 1,
					visibility: "visible",
					ease: Power4.easeOut,
				},
				"-=0.2"
			)
			.add("preClose", "-=0.8")
			.to(
				closeTriggerLeft,
				0.8,
				{
					x: "-=100px",
					y: "+=100px",
					ease: Power4.easeOut,
				},
				"preClose"
			)
			.to(
				closeTriggerRight,
				0.8,
				{
					x: "+=100px",
					y: "+=100px",
					delay: 0.2,
					ease: Power4.easeOut,
				},
				"preClose"
			);
		//CLOSE TIMELINE
		tlClose
			.add("close")
			.to(
				menuTop,
				0.2,
				{
					backgroundColor: "#000",
					ease: Power4.easeInOut,
					onComplete: function () {
						closeTrigger.style.zIndex = "5";
						openTrigger.style.visibility = "visible";
					},
				},
				"close"
			)
			.to(
				menuMiddle,
				0.2,
				{
					backgroundColor: "#000",
					ease: Power4.easeInOut,
				},
				"close"
			)
			.to(
				menuBottom,
				0.2,
				{
					backgroundColor: "#000",
					ease: Power4.easeInOut,
				},
				"close"
			)
			.to(
				menu,
				0.6,
				{
					y: 20,
					opacity: 0,
					ease: Power4.easeOut,
					onComplete: function () {
						menu.style.visibility = "hidden";
					},
				},
				"close"
			)
			.to(
				menuTop,
				0.8,
				{
					y: "-113%",
					ease: Power4.easeInOut,
				},
				"close",
				"+=0.2"
			)
			.to(
				menuMiddle,
				0.8,
				{
					scaleY: 0,
					ease: Power4.easeInOut,
				},
				"close",
				"+=0.2"
			)
			.to(
				menuBottom,
				0.8,
				{
					y: "23%",
					ease: Power4.easeInOut,
					onComplete: function () {
						menuTop.style.backgroundColor = "#000";
						menuMiddle.style.backgroundColor = "#000";
						menuBottom.style.backgroundColor = "#000";
					},
				},
				"close",
				"+=0.2"
			)
			.to(
				closeTriggerLeft,
				0.2,
				{
					x: "+=100px",
					y: "-=100px",
					ease: Power4.easeIn,
				},
				"close"
			)
			.to(
				closeTriggerRight,
				0.2,
				{
					x: "-=100px",
					y: "-=100px",
					delay: 0.1,
					ease: Power4.easeIn,
				},
				"close"
			)
			.to(
				openTriggerTop,
				1,
				{
					x: "-=80px",
					y: "+=80px",
					delay: 0.2,
					ease: Power4.easeOut,
				},
				"close"
			)
			.to(
				openTriggerMiddle,
				1,
				{
					x: "-=80px",
					y: "+=80px",
					ease: Power4.easeOut,
				},
				"close"
			)
			.to(
				openTriggerBottom,
				1,
				{
					x: "-=80px",
					y: "+=80px",
					delay: 0.1,
					ease: Power4.easeOut,
				},
				"close"
			);
		document.addEventListener(
			"DOMContentLoadedDOMContentLoaded",
			function () {
				var menuTrigger = document.querySelector(".menu-trigger");
				var mobileContainer =
					document.querySelector(".mobile-container");

				menuTrigger.addEventListener("click", function () {
					mobileContainer.classList.toggle("show");
				});
			}
		);
		//EVENTS
		openTrigger.addEventListener("click", function () {
			if (tlOpen.progress() < 1) {
				tlOpen.play();
			} else {
				tlOpen.restart();
			}
		});
		closeTrigger.addEventListener("click", function () {
			if (tlClose.progress() < 1) {
				tlClose.play();
			} else {
				tlClose.restart();
			}
		});
	},
};
</script>

<style scoped>
* {
	font-family: "Tomorrow", -apple-system, BlinkMacSystemFont, "Segoe UI",
		Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
		"Noto Color Emoji";
	font-weight: 600;
}
nav {
	display: flex;
	justify-content: space-between;
	position: fixed;
	z-index: 15;
	width: 100%;
	margin: 10px 0 0 25px;
	pointer-events: none;
}
.logoresp {
	display: none;
	position: fixed;
	top: 5%;
	left: 5%;
	width: 50px;
	pointer-events: all;
}
.left-nav {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 20%;
	margin: 0 50px 0 0;
}
.right-nav {
	position: relative;
	display: flex;
	justify-content: flex-end;
	padding: 0 50px 0 0;
	width: 80%;
	pointer-events: none;
}
.dori {
	padding: 10px;
	width: 100px;
	cursor: pointer;
	pointer-events: all;
}
.btn-21 {
	cursor: pointer;
	text-transform: uppercase;
	font-size: 0.75rem;
	margin: 20px 10px;
	height: 22.5px;
	border-radius: 5px;
	transition: 0.5s ease;
	position: relative;
	color: #777;
	z-index: 1;
	overflow: hidden;
	border: none;
	text-decoration: none;
	pointer-events: all;
}
.btn-21 {
	padding: 5.5px 10px;
}
.social {
	padding: 5px 0;
}
.btn-21 span {
	display: block;
	min-width: 100%;
	min-height: 100%;
	line-height: 20px;
	text-decoration: none;
	color: #000;
}
.btn-21:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: linear-gradient(to right, transparent, #bbb);
	animation: btn-21-right 2s linear infinite;
	transition: 0.5s ease-in-out;
}

.btn-21:after {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	width: 2px;
	height: 100%;
	background: linear-gradient(to bottom, transparent, #bbb);
	animation: btn-21-bottom 2s linear infinite;
	animation-delay: 1s;
	transform: translateY(-100%);
}
.btn-21 span:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: linear-gradient(to left, transparent, #bbb);
	animation: btn-21-left 2s linear infinite;
}

.btn-21 span:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 2px;
	height: 100%;
	background: linear-gradient(to top, transparent, #bbb);
	animation: btn-21-top 2s linear infinite;
	animation-delay: 1s;
	transform: translateY(-100%);
}
.btn-21:hover {
	background: #dbdbdb5e;
}
@keyframes btn-21-right {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}

@keyframes btn-21-top {
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(-100%);
	}
}

@keyframes btn-21-left {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(-100%);
	}
}

@keyframes btn-21-bottom {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(100%);
	}
}
.btn-21:focus {
	outline: none;
}
.fa-arrow-up-right-from-square {
	padding: 0 5px;
}
.buy-container {
	position: relative;
	padding: 5px 10px;
}
.twitter,
.discord {
	display: flex;
	align-items: center;
	justify-content: center;
}
.fa-twitter,
.fa-discord {
	margin: 5px 15px;
}

/* ------------------------------------------------- MOBILE NAV ------------------------------------------------- */
.mobile-container {
	display: none;
	width: 100%;
	min-width: 100vw;
	height: 100%;
	min-height: 100vh;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	-webkit-box-shadow: 0px 9px 13px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0px 9px 13px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 9px 13px 0px rgba(0, 0, 0, 0.3);
	-webkit-transform: scale(1);
	z-index: 18;
	pointer-events: none;
}
.mobile-container.show {
	display: block;
}
.menu-trigger,
.close-trigger {
	display: none;
	position: absolute;
	top: 6%;
	right: 8%;
	width: 42px;
	height: 42px;
	cursor: pointer;
	z-index: 333;
	transform: scale(3);
}
.menu-trigger:hover .menu-trigger-bar:before,
.close-trigger:hover .menu-trigger-bar:before {
	width: 100%;
}
.menu-trigger:hover .close-trigger-bar:before,
.close-trigger:hover .close-trigger-bar:before {
	width: 100%;
}
.close-trigger {
	z-index: 5;
	top: 8%;
	right: 9%;
}
.menu-trigger-bar {
	display: block;
	width: 100%;
	height: 4px;
	background-color: #000;
	margin-bottom: 6px;
	transform: rotate(-45deg);
	position: relative;
}
.menu-trigger-bar:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 0%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}
.menu-trigger-bar.top {
	width: 50%;
}
.menu-trigger-bar.middle:before {
	left: auto;
	right: 0;
}
.menu-trigger-bar.bottom {
	width: 50%;
	margin-left: 50%;
}
.close-trigger-bar {
	display: block;
	width: 100%;
	height: 4px;
	background-color: #fff;
	position: relative;
}
.close-trigger-bar:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 0%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.2);
	transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}
.close-trigger-bar.left {
	transform: translateX(100px) translateY(-100px) rotate(-45deg);
}
.close-trigger-bar.right {
	transform: translateX(-100px) translateY(-100px) rotate(45deg);
	top: -3px;
}
.inner-container,
.menu-container {
	position: absolute;
	height: 100%;
	min-height: 100vh;
	width: 100%;
	min-width: 100vw;
	left: 0;
	top: 0;
}
.inner-container {
	z-index: 20;
}
.menu-container {
	display: flex;
	align-items: center;
}
.menu {
	position: absolute;
	left: -30%;
	display: block;
	width: 120%;
	margin: -75px 0 0 0;
	padding: 0% 20% 20% 20%;
	transform: scale(0.5);
	-webkit-transform: scale(0.5);
	pointer-events: all;
}
.menu .mobile-li {
	text-align: left;
	display: block;
	padding: 15px 0;
	border-bottom: 3px solid #fff;
	display: flex;
	justify-content: space-between;
	color: white;
	align-items: center;
	font-size: 3rem;
}
.link-li img {
	width: 75px;
	height: 75px;
}
.link-li {
	text-align: left;
	padding: 20px 0;
	border-bottom: 3px solid #fff;
	display: flex;
	justify-content: space-between;
	color: white;
	align-items: center;
	font-size: 3rem;
}
#hub img {
	height: 50px;
	width: 50px;
	margin: 0 12.5px 0 0;
}
#opensea {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
#twitter {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
#twitter img {
	height: 45px;
	width: 55px;
	margin: 0 10px 0 0;
}
#discord {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
#discord img {
	height: 65px;
	width: 60px;
	margin: 0 10px 0 0;
}
.menu a {
	text-decoration: none;
	color: #fff;
	display: inline-block;
	padding: 10px 50% 10px 0;
	position: relative;
	font-size: 3rem;
}
.menu-bg {
	position: absolute;
	display: block;
	width: 200%;
	left: -53%;
	top: -25%;
	height: 40%;
	background: #000;
}
.bg-mobile {
	position: relative;
}
.dori-mobile-logo {
	margin: 100px 0 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.dori-mobile-logo img {
	width: 500px;
	z-index: -10;
}
.menu-bg.middle {
	top: 18%;
	left: -67%;
	width: 2680px;
	height: 1000px;
	transform: rotate(-45deg) scaleY(0);
}
.menu-bg.top {
	left: 45%;
	top: -28%;
	width: 1500px;
	height: 1000px;
	transform: rotate(-45deg) translateY(-152%);
}
.menu-bg.bottom {
	top: 105%;
	width: 200%;
	transform: rotate(-45deg) translateY(25%);
	left: -20%;
}
/* ------------------------------------------------ RESPONSIVE------------------------------------------------ */
@media screen and (max-width: 3840px) {
	.dori {
		padding: 40px;
		width: 250px;
	}
	.right-nav {
		padding: 0 850px 0 0;
		transform: scale(2);
	}
	.btn-21 {
		margin: 70px 10px;
	}
}
@media screen and (max-width: 2560px) {
	.btn-21 {
		cursor: pointer;
		text-transform: uppercase;
		font-size: 0.75rem;
		margin: 40px 10px;
		height: 22.5px;
		border-radius: 5px;
		transition: 0.5s ease;
		position: relative;
		color: #777;
		z-index: 1;
		overflow: hidden;
		border: none;
		text-decoration: none;
	}
	.dori {
		padding: 20px;
		width: 200px;
	}
	.right-nav {
		padding: 0 400px 0 0;
		transform: scale(1.5);
	}
	.buy {
		line-height: 25.5px;
	}
	.btn-21 span {
		line-height: 25.5px;
	}
	.buy-container {
		padding: 5px 10px;
	}
	.fa-twitter,
	.fa-discord {
		margin: 5px 15px;
	}
}
@media screen and (max-width: 2048px) {
	nav {
		display: flex;
		justify-content: space-between;
		position: fixed;
		z-index: 15;
		width: 100%;
		margin: 10px 0 0 25px;
	}
	.left-nav {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 20%;
		margin: 0 50px 0 0;
	}
	.right-nav {
		display: flex;
		justify-content: flex-end;
		padding: 0 50px 0 0;
		width: 80%;
		transform: scale(1);
	}
	.btn-21 {
		font-size: 0.75rem;
		margin: 20px 10px;
		height: 23.5px;
		border-radius: 5px;
		transition: 0.5s ease;
		position: relative;
		color: #777;
		z-index: 1;
		overflow: hidden;
		border: none;
		text-decoration: none;
	}
	.btn-21 span {
		line-height: 23.5px;
	}
	.dori {
		padding: 10px;
		width: 100px;
		cursor: pointer;
	}
	.desktop-li:hover {
		background: #4b4b4b3b;
	}
	.fa-angle-down {
		padding: 0 5px;
	}
	.buy {
		line-height: 25.5px;
	}
	.buy-container {
		position: relative;
		padding: 5px 10px;
	}
	.twitter,
	.discord {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.fa-twitter,
	.fa-discord {
		margin: 5px 15px;
	}
}
@media screen and (max-width: 1520px) {
	.btn-21 {
		height: 17.5px;
	}
	.btn-21 span {
		line-height: 17.5px;
	}
	.buy {
		line-height: 18.5px;
	}
	.fa-twitter,
	.fa-discord {
		margin: 0px 15px 5px;
	}
}
@media screen and (max-width: 1260px) {
	.btn-21 {
		height: 18px;
		margin: 5px 7.5px;
	}
	btn-21 span {
		line-height: 15px;
	}
	.dori {
		margin: 0;
		padding: 0px;
		width: 100px;
		cursor: pointer;
	}
	.buy-container {
		position: relative;
		padding: 5px 5px;
	}
	.buy {
		line-height: 18px;
	}
}
@media screen and (max-width: 1080px) {
	nav {
		margin: 0;
		pointer-events: none;
	}
	.logoresp {
		display: block;
		position: fixed;
		top: 2%;
		left: 2%;
		width: 100px;
	}
	.left-nav {
		display: none;
	}
	.right-nav {
		display: none;
	}
	.mobile-container {
		display: block;
	}
	.menu-trigger,
	.close-trigger {
		display: block;
		transform: scale(1);
		top: 2%;
		right: 2%;
		pointer-events: all;
	}
	.menu {
		position: absolute;
		left: -30%;
		display: block;
		width: 120%;
		margin: 100px 0 0 0;
		padding: 0% 20% 20% 20%;
		transform: scale(0.3) !important;
	}
	.menu a {
		padding: 10px 75% 10px 0;
	}
	.dori-mobile-logo img {
		width: 750px;
		z-index: -10;
	}
	.menu-bg.top {
		left: -60%;
		top: -50%;
		width: 3000px;
		height: 1000px;
	}
	.menu-bg.middle {
		top: 18%;
		left: -105%;
		width: 3000px;
		height: 1000px;
	}
	.menu-bg.bottom {
		top: 135%;
		width: 3000px;
		height: 1000px;
		left: -20%;
	}
}
@media screen and (max-width: 920px) {
	nav {
		margin: 0;
		pointer-events: none;
	}
	.logoresp {
		display: block;
		position: fixed;
		top: 2%;
		left: 4%;
		width: 150px;
	}
	.left-nav {
		display: none;
	}
	.right-nav {
		display: none;
	}
	.mobile-container {
		display: block;
	}
	.menu-trigger,
	.close-trigger {
		display: block;
		transform: scale(1.5);
		top: 4%;
		right: 5%;
		pointer-events: all;
	}
	.menu {
		position: absolute;
		left: -30%;
		display: block;
		width: 120%;
		margin: 100px 0 0 0;
		padding: 0% 20% 20% 20%;
		transform: scale(0.6) !important;
	}
	.menu a {
		padding: 10px 75% 10px 0;
	}
	.dori-mobile-logo img {
		width: 750px;
		z-index: -10;
	}
	.menu-bg.top {
		left: 45%;
		top: -28%;
		width: 1500px;
		height: 1000px;
	}
	.menu-bg.bottom {
		top: 120%;
		width: 200%;
		transform: rotate(-45deg) translateY(25%);
		left: -20%;
	}
}
@media screen and (max-width: 770px) {
	.menu-bg.top {
		left: 45%;
		top: -28%;
		width: 1500px;
		height: 900px;
		transform: rotate(-45deg) translateY(-152%);
	}
	.dori-mobile-logo img {
		width: 500px;
		z-index: -10;
	}
	.logoresp {
		top: 2%;
		left: 4%;
		width: 150px;
	}
}
@media screen and (max-width: 660px) {
	.menu-bg.middle {
		top: -38%;
		left: -67%;
		width: 2680px;
		height: 1000px;
		transform: rotate(-45deg) scaleY(0);
	}
}

@media screen and (max-width: 540px) {
	.logoresp {
		top: 3%;
		left: 6%;
		width: 100px;
	}
	.menu-trigger,
	.close-trigger {
		transform: scale(1);
	}
	.menu a {
		padding: 10px 50% 10px 0;
	}
	.menu {
		margin: 50px 0 0 0;
		padding: 0% 20% 20% 20%;
		transform: scale(0.45) !important;
	}
}
@media screen and (max-width: 420px) {
	.menu .mobile-li {
		font-size: 2.5rem;
	}
	.menu-trigger,
	.close-trigger {
		transform: scale(1);
		top: 3.25%;
	}
	.logoresp {
		top: 2.5%;
		left: 6%;
		width: 100px;
	}
	.menu-bg.middle {
		top: -30%;
		left: -150%;
		width: 3000px;
		height: 1000px;
	}
	.menu-bg.bottom {
		top: 106%;
		left: -20%;
	}
	.menu {
		margin: 25px 0 0 0;
		padding: 0% 20% 20% 20%;
		transform: scale(0.5) !important;
	}
	.menu a {
		padding: 5px 50% 5px 0;
		font-size: 2.5rem;
	}
	.link-li {
		padding: 10px 0;
		font-size: 2.5rem;
	}
	.link-li img {
		width: 65px;
		height: 65px;
	}
	#hub img {
		height: 40px;
		width: 40px;
		margin: 0 10px 0 0;
	}
	#twitter img {
		height: 40px;
		width: 45px;
		margin: 0 10px 0 0;
	}
	#discord img {
		height: 60px;
		width: 55px;
		margin: 0 10px 0 0;
	}
}
@media screen and (max-width: 380px) {
	.menu {
		position: absolute;
		left: -35%;
		display: block;
		width: 130%;
		margin: 35px 0 0 0;
		padding: 0% 20% 20% 20%;
		transform: scale(0.4) !important;
	}
}
@media screen and (max-width: 300px) {
	.dori-mobile-logo img {
		width: 400px;
		z-index: -10;
	}
	.menu {
		position: absolute;
		left: -50%;
		display: block;
		width: 160%;
		margin: 35px 0 0 0;
		padding: 0% 20% 20% 20%;
		transform: scale(0.4) !important;
	}
}
@media screen and (max-width: 200px) {
	.menu-bg.middle {
		top: -20%;
		left: -400%;
		width: 3000px;
		height: 1000px;
		transform: rotate(-45deg) scaleY(0);
	}
}
</style>
