<template>
	<link
		rel="stylesheet"
		href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
		integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw=="
		crossorigin="anonymous"
		referrerpolicy="no-referrer"
	/>
	<div class="card-container">
		<div class="container">
			<img
				class="dori-logo"
				src="../assets/img/dori-logo_web.png"
				alt="dori-logo_web"
			/>
			<img
				class="character"
				src="../assets/img/DORI_CYBER_GIRL_v2.webp"
				alt="character"
			/>
			<div class="circle" @click="playAudio()">
				<i class="fa-solid fa-play" id="play-button"></i>
			</div>
			<audio ref="audio" preload="auto" loop :src="audioFile"></audio>
		</div>
	</div>
</template>
<script>
import audioFile from "../assets/song/Warzone.mp3";
export default {
	data() {
		return {
			isPlaying: false,
			audioFile: audioFile,
		};
	},
	methods: {
		playAudio() {
			let playButton = document.getElementById("play-button");
			let audio = this.$refs.audio;
			if (this.isPlaying) {
				audio.pause();
				this.isPlaying = false;
				playButton.classList.toggle("fa-play");
				playButton.classList.toggle("fa-pause");
			} else {
				audio.play();
				this.isPlaying = true;
				playButton.classList.toggle("fa-play");
				playButton.classList.toggle("fa-pause");
			}
		},
	},
};
</script>

<style scoped>
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.card-container {
	position: fixed;
	z-index: 5;
	bottom: 2%;
	left: 1%;
}
.container {
	position: relative;
	width: 125px;
	height: 60px;
	border-radius: 10px;
	background: #000;
}
.dori-logo {
	position: absolute;
	z-index: 7;
	right: 12.5%;
	bottom: 37.5%;
	width: 50px;
}
.character {
	z-index: 6;
	width: 70px;
	position: absolute;
	left: -10%;
	bottom: 0%;
}
.circle {
	position: absolute;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	right: -20%;
	top: -40%;
	background: #000;
	border: 1px solid #000;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}
.fa-play {
	color: white;
	font-size: 1.25rem;
}
.fa-pause {
	color: white;
	font-size: 1.25rem;
}

/* -------------------------- RESPONSIVE  -------------------------- */

@media screen and (max-width: 3840px) {
	.container {
		zoom: 200%;
	}
}
@media screen and (max-width: 2560px) {
	.container {
		zoom: 150%;
	}
}
@media screen and (max-width: 2048px) {
	.container {
		zoom: 100%;
	}
}
@media screen and (max-width: 1705px) {
	.container {
		zoom: 85%;
	}
}
@media screen and (max-width: 1260px) {
	.container {
		zoom: 65%;
	}
}
@media screen and (max-width: 920px) {
	.container {
		display: none;
	}
}
</style>
