<template>
	<link rel="preconnect" href="https://fonts.googleapis.com/%22%3E" />
	<link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
	<link
		href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&family=Tomorrow:wght@400;600;700&display=swap"
		rel="stylesheet"
	/>
	<link
		href="https://fonts.googleapis.com/css?family=IBM%20Plex%20Sans:400%7CTomorrow:400&display=swap"
		rel="stylesheet"
	/>
	<div id="app">
		<navbar />
		<music />
		<preloader />
		<RouterView />
	</div>
</template>

<script>
import navbar from "./components/navbar.vue";
import music from "./components/music.vue";
import preloader from "./components/preloader.vue";

export default {
	name: "App",
	components: {
		navbar,
		music,
		preloader,
	},
};
</script>

<style>
#app {
	margin: 0;
	padding: 0;
	height: -webkit-fill-available;
}
* {
	margin: 0;
	padding: 0;
	color: #000;
	list-style-type: none;
	text-decoration: none;
	user-select: none;
}
body::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* color of the scrollbar track */
body::-webkit-scrollbar-track {
	background: #ffffff;
}
/* color of the scrollbar thumb */
body::-webkit-scrollbar-thumb {
	background: rgba(104, 104, 104, 0.603);
}
body {
	height: -webkit-fill-available;
}
</style>
